import { useEffect, useState,useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// @mui
import { 
    Grid,
    Box,
    Stack,
    Button,
    TextField
 } from '@mui/material';

 // Axios
import axios from 'axios';
import UserContext from "../../context/AuthContext";

import AddIcon from '@mui/icons-material/Add';
import ImageUpload from '../upload/image';


BenefitCreate.propTypes = {
  updateState:PropTypes.func
};

function BenefitCreate({updateState,...other}) {

    const { jwt  } = useContext(UserContext);
    const navigate = useNavigate();
    const [imgBenefit,setImgBenefit] = useState();
    const [imgBenefit2,setImgBenefit2] = useState();


    // Data universal
    const [postCurso,setPostCurso] = useState({
        fTitulo:'',
        fDescripcion:'',
        fCobertura:'',
        fNveces:'',
        fLink:'',
    });

    const changePostCurso = (e) =>{
        setPostCurso({
            ...postCurso,
            [e.target.name]: e.target.value
        });
    }

    // Enviar data
    const baseUrl  = 'https://api.lessin.pe/wp-json/wp/v2/beneficios';

    const registrarCurso = () =>{
        updateState(true)
        axios.post(baseUrl,
        {
            title:postCurso.fTitulo,
            status:"publish",
            acf:{
                pbene_img:imgBenefit ? imgBenefit.image : '',
                pbene_img_modal:imgBenefit2 ? imgBenefit2.image : '',
                pbene_desc_corta:postCurso.fDescripcion,
                pbene_cobertura:postCurso.fCobertura,
                pbene_nveces:postCurso.fNveces,
                pbene_link:postCurso.fLink,
            }
        },
        {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
            }
        }).then((data)=>{
            updateState(false)
            navigate('/dashboard/benefit/edit-benefit/'+data.data.id);
        }).catch((error) => {
            console.log('error',error);
        });
    }

    useEffect(()=>{
    },[])

    return (
        <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                        <ImageUpload 
                            updateState={setImgBenefit} 
                            recomend={'Beneficio: Tamaño recomendado 450x220 pixeles'} 
                            imageData={imgBenefit} 
                            height={'200px'}
                        />
                        <ImageUpload 
                            updateState={setImgBenefit2} 
                            recomend={'Beneficio popup: Tamaño recomendado 450x150 pixeles'} 
                            imageData={imgBenefit2} 
                            height={'150px'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField 
                                    id="outlined-basic" 
                                    fullWidth 
                                    label="Titulo" 
                                    onChange={changePostCurso} 
                                    value={postCurso.fTitulo} 
                                    name="fTitulo" 
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField 
                                    id="outlined-basic" 
                                    fullWidth 
                                    label="Descripcion" 
                                    onChange={changePostCurso} 
                                    value={postCurso.fDescripcion} 
                                    name="fDescripcion" 
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField 
                                    id="outlined-basic" 
                                    fullWidth 
                                    label="Cobertura" 
                                    onChange={changePostCurso} 
                                    value={postCurso.fCobertura} 
                                    name="fCobertura" 
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField 
                                    id="outlined-basic" 
                                    fullWidth 
                                    label="Numero de veces" 
                                    onChange={changePostCurso} 
                                    value={postCurso.fNveces} 
                                    name="fNveces" 
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField 
                                    id="outlined-basic" 
                                    fullWidth 
                                    label="Link" 
                                    onChange={changePostCurso} 
                                    value={postCurso.fLink} 
                                    name="fLink" 
                                />
                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>
            </Stack>

            <Button 
                variant="contained" 
                startIcon={<AddIcon />}
                sx={{mt:2}}
                onClick={registrarCurso}
            >
                Crear
            </Button>
        </Box>
    );

}

export default BenefitCreate;
