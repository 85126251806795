import { useEffect, useState,useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
import { faker } from '@faker-js/faker';

// @mui
import { 
    Box,
    Grid,
    Stack,
    Button,
    TextField,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox
 } from '@mui/material';

 // Axios
import axios from 'axios';
import UserContext from "../../context/AuthContext";

import AddIcon from '@mui/icons-material/Add';

CourseInfo.propTypes = {
  updateState:PropTypes.func
};

function CourseInfo({updateState,...other}) {

    const { jwt  } = useContext(UserContext);
    const { id } = useParams();


    // Mostrar categorias
    const urlCategorias  = 'https://api.lessin.pe/wp-json/wp/v2/categoria';
    const [categoriasList,setCategoriasList] = useState();
    const getCategorias =  () =>{
        axios.get(urlCategorias).then((data)=>{
        setCategoriasList(data.data);
        });
    }

    // Categorias
    const[categoriaCheck,setCategoriaCheck] = useState([]);
    const changeCategria = (e) =>{
        
        if(e.target.checked) {
        setCategoriaCheck([
            ...categoriaCheck,
            parseInt(e.target.value)
        ])
        }else{
        setCategoriaCheck((current,index) =>
            current.filter((item) =>{
            return parseInt(item) !== parseInt(e.target.value);
            })
        );
        }
        
    }


    // Data universal
    const [postCurso,setPostCurso] = useState({
        fEstudiantes:'',
        fDuracion:'',
        fLecciones:'',
        fNivel:'',
        fCertificado:'',
    });

    const changePostCurso = (e) =>{
        setPostCurso({
            ...postCurso,
            [e.target.name]: e.target.value
        });
    }

    // Enviar data
    const baseUrl  = 'https://api.lessin.pe/wp-json/wp/v2/cursos';

    const registrarCurso = () =>{
        updateState(true)
        axios.put(baseUrl+'/'+id,
        {
            acf:{
                pcurso_estudiantes:postCurso.fEstudiantes,
                pcurso_duracion:postCurso.fDuracion,
                pcurso_lecciones:postCurso.fLecciones,
                pcurso_nivel:postCurso.fNivel,
                pcurso_certificado:postCurso.fCertificado,
            },
            categoria:categoriaCheck,
        },
        {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
            }
        }).then((data)=>{
            updateState(false)
        }).catch((error) => {
            console.log('error',error);
        });
    }


    // Get Informations
    const getCourse = () =>{
        updateState(true)
        axios.get(baseUrl+'/'+id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            }
        }
        ).then((resp)=>{
            setPostCurso({
                fEstudiantes: resp.data.acf.pcurso_estudiantes,
                fDuracion: resp.data.acf.pcurso_duracion,
                fLecciones: resp.data.acf.pcurso_lecciones,
                fNivel: resp.data.acf.pcurso_nivel,
                fCertificado: resp.data.acf.pcurso_certificado,
            });
            setCategoriaCheck(resp.data.categoria);
            updateState(false)
        })
    }


    useEffect(()=>{
        getCourse();
        getCategorias();
    },[])

    return (
        <Box>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6}>
                    <Stack spacing={2}>
                        <TextField id="outlined-basic" fullWidth label="Estudiantes" onChange={changePostCurso} value={postCurso.fEstudiantes} name="fEstudiantes" variant="outlined" />
                        <TextField id="outlined-basic" fullWidth label="Duración" onChange={changePostCurso} value={postCurso.fDuracion} name="fDuracion" variant="outlined" />
                        <TextField id="outlined-basic" fullWidth label="Lecciones" onChange={changePostCurso} value={postCurso.fLecciones} name="fLecciones" variant="outlined" />
                        <TextField id="outlined-basic" fullWidth label="Nivel" onChange={changePostCurso} value={postCurso.fNivel} name="fNivel" variant="outlined" />
                        <TextField id="outlined-basic" fullWidth label="Certificado" onChange={changePostCurso} value={postCurso.fCertificado} name="fCertificado" variant="outlined" />
                    </Stack>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="h6" sx={{ mb: 0 }}  onClick={()=> console.log(categoriaCheck)}>
                        Categorias
                    </Typography>
                    {categoriasList &&
                        <FormGroup onChange={changeCategria}>
                            {categoriasList.map((item,index) => {
                                const labelId = `checkbox-list-label-${item.id}`;
                                var itemValTemp = null;
                                    if(categoriaCheck.length > 0 ){
                                    categoriaCheck.map((item2)=>{
                                        if(parseInt(item.id) === parseInt(item2)){
                                        itemValTemp = item2;
                                        }
                                    })

                                    if(parseInt(item.id) === parseInt(itemValTemp)){
                                        return (<FormControlLabel value={item.id} key={faker.datatype.uuid()} control={<Checkbox defaultChecked />}   label={item.name} />);
                                    }else{
                                        return (<FormControlLabel value={item.id}  control={<Checkbox />}   label={item.name} />);
                                    }
                                }else{
                                    return (<FormControlLabel value={item.id}  control={<Checkbox />}   label={item.name} />);
                                } 
                            })}
                        </FormGroup>
                    }

                </Grid>
                <Grid item xs={12} sm={12} md={6}>

                    <Button 
                        variant="contained" 
                        startIcon={<AddIcon />}
                        sx={{mt:0}}
                        onClick={registrarCurso}
                    >
                        Actualizar datos
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );

}

export default CourseInfo;
