import { useEffect, useState,useContext } from 'react';
import PropTypes from 'prop-types';
import { faker } from '@faker-js/faker';
import { useParams } from "react-router-dom";

// @mui
import { 
    Card,
    CardContent ,
    Grid,
    Typography, 
    TextField, 
    Box,
    Stack, 
    Modal,
    Button,
    List,
    ListItem,
    ListItemText,
    Divider,
    Alert,
    IconButton,
    Chip
 } from '@mui/material';

// Iconos
import Iconify from '../../components/iconify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DoneIcon from '@mui/icons-material/Done';

 // Axios
import axios from 'axios';
import UserContext from "../../context/AuthContext";

function AgregarModulo(props) {
  const { value, cambioField, enviarModulo,action, ...other } = props;
  return  (
    <Stack spacing={3}>
      <Typography variant="h4" sx={{ mb: 0 }}>
          Agregar pregunta
      </Typography>
      <TextField value={value.tituloModulo} onChange={cambioField} id="tituloModulo" name="tituloModulo" fullWidth label="Pregunta" variant="outlined" {...other }/>
      <TextField value={value.respuestaModulo} onChange={cambioField} id="respuestaModulo" name="respuestaModulo" fullWidth label="Respuesta" variant="outlined" {...other }/>
      <Button 
        variant="contained" 
        startIcon={<Iconify icon="eva:plus-fill" />}
        onClick={enviarModulo}
      >
        {action ? 'Agregar' : 'Actualizar'}+
      </Button>

    </Stack>
  );
}
  
AgregarModulo.propTypes = {
  enviarModulo: PropTypes.func,
  cambioField: PropTypes.func,
  action: PropTypes.bool,
  value: PropTypes.string,
};

function AgregarClase(props){
    const { enviarExamen, title,examenChange,action, ...other } = props;
    return (
        <>
        <Grid container spacing={3} >
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h4" sx={{ mb: 0 }}>
                {action ? 'Agregar opción': 'Editar opción'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField id="outlined-basic" fullWidth label="Titulo" name="pcurso_examen_opitem" value={title} onChange={examenChange} variant="outlined" />
          </Grid>

          <Grid item xs={12} sm={12} md={12} >
            <Button 
              variant="contained" 
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={enviarExamen}
            >
                {action ? 'Agregar': 'Actualizar'}
            </Button>
          </Grid>
        </Grid>
        </>
    );
};
  
AgregarClase.propTypes = {
    enviarExamen: PropTypes.func,
    title: PropTypes.string,
    examenChange: PropTypes.func,
    action:PropTypes.bool
};
  
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    boxShadow: 24,
};


CourseExamen.propTypes = {
    updateState:PropTypes.func
};


function CourseExamen({updateState,...other}) {

  const { jwt  } = useContext(UserContext);
  const { id } = useParams();
  const [examen,setExamen] = useState([]);
  const [tituloModulo,setTituloModulo] = useState({
    tituloModulo:'',
    respuestaModulo:''
  });
  const [openModuleModal, setOpenModuleModal] = useState(false);
  const [openModuloId, setOpenModuloId] = useState();
  const [actionModuleModal,setActionModuleModal] = useState(false);

  const handleModuleOpen = () => {
      setOpenModuleModal(true);
      setActionModuleModal(true);
      setTituloModulo({
        tituloModulo:'',
        respuestaModulo:''
      });
  };

  const handleModuleClose = () => {
      setOpenModuleModal(false);
      setActionModuleModal(true);
  };

  const [openModal, setOpenModal] = useState(false);
  const [actionModal,setActionModal] = useState(false);

  const handleOpen = (id) => {
      setOpenModal(true);
      setOpenModuloId(id);
      setActionModal(true);
  };

  const handleClose = () => {
      setOpenModal(false)
  };

  const fieldModulo = (event) =>{
      setTituloModulo({
        ...tituloModulo,
        [event.target.name]:event.target.value
      });
  }

  const agregarTituloModulo = () =>{
      setExamen([...examen,
        {
          pcurso_examen_pregunta:tituloModulo.tituloModulo,
          pcurso_examen_respuesta:tituloModulo.respuestaModulo,
          id:faker.datatype.uuid(),
          pcurso_examen_opciones:[]
        }])
      setTituloModulo({
        tituloModulo:'',
        respuestaModulo:''
      });
      setOpenModuleModal(false);
  }

  const actualizarTituloModulo = (id) =>{

      setExamen(
        examen.map((examenItem,index) =>{
          if(id === examenItem.id){
            return { ...examenItem,
              pcurso_examen_pregunta:tituloModulo.tituloModulo,
              pcurso_examen_respuesta:tituloModulo.respuestaModulo
            }
          }else{
            return {...examenItem}          
          }
        })
      );
      setTituloModulo({
        tituloModulo:'',
        respuestaModulo:''
      });
      setOpenModuleModal(false);
  }
  
  const eliminarModulo = (item)=>{
    setExamen(
      examen.filter((examenIn) => examenIn.id !== item.id)
    );
  }

  const editarModulo = (item)=>{
    handleModuleOpen(true);
    setTituloModulo({
      tituloModulo:item.pcurso_examen_pregunta,
      respuestaModulo:item.pcurso_examen_respuesta
    })
    setActionModuleModal(false);
    setOpenModuloId(item.id);
  }

  const [clase,setClase] = useState({
    id:'',
    pcurso_examen_opitem:''
  });

  const enviarExamen = (id)=>{
    setExamen(
    examen.map((examen,index) =>{
        if(id === examen.id){
          return {...examen, 
            pcurso_examen_opciones:
              [...examen.pcurso_examen_opciones,
                {
                  id:faker.datatype.uuid(),
                  pcurso_examen_opitem:clase.pcurso_examen_opitem,
                }
              ]
            }
        }else{
          return {...examen}
        }
    })
    );
    setOpenModal(false);
    setClase({
      id:'',
      pcurso_examen_opitem:'',
    });
  }

  const [classListTemp,setClassListTemp] = useState([]);
  const actualizarClase = (id,item) =>{
      const newState = classListTemp.map(obj => {
      if (obj.id === item.id) {
          return {...obj, 
            id: clase.id,
            pcurso_examen_opitem:clase.pcurso_examen_opitem,
          };
      }
      return obj;
      });
      
      setExamen(
        examen.map((examen,index) =>{
          if(id === examen.id){
            return {...examen,pcurso_examen_opciones:newState}
          }else{
            return {...examen}
          }
        })
      );
      
      setOpenModal(false);

      setClase({
        id:'',
        pcurso_examen_opitem:'',
      });
  }

  const examenChange = (event) =>{
      setClase({
        ...clase,
        [event.target.name]: event.target.value
      });
  }

  const editClass = (item,itemSub)=>{
      setOpenModal(true);
      setActionModal(false);
      setClase(itemSub);
      setOpenModuloId(item.id);
      setClassListTemp(item.pcurso_examen_opciones);
  }

  const eliminarClase = (item,itemSub) =>{

      const newState = item.pcurso_examen_opciones.filter(obj => {
      return obj.id !== itemSub.id;
      });

      setExamen(
        examen.map((examen,index) =>{
          if(item.id === examen.id){
            return {...examen,pcurso_examen_opciones:newState}
          }else{
            return {...examen}
          }
        })
      );
  }

  // Enviar data
  const baseUrl  = 'https://api.lessin.pe/wp-json/wp/v2/cursos';

  const registrarCurso = () =>{
    updateState(true);

    axios.put(baseUrl+'/'+id,
      {
        acf:{
          pcurso_examen:examen,
        }
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }).then((data)=>{
        updateState(false);
        
      }).catch((error) => {
        console.log('error',error);
      });
  }

  // Get Informations
  const getCourse = () =>{
    updateState(true)

    axios.get(baseUrl+'/'+id,
      {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        }
      }
    ).then((resp)=>{
      if(resp.data.acf.pcurso_examen.length> 0){
        const listaTmp = [];
        
        resp.data.acf.pcurso_examen.map((item)=>{
          const listaTmpSub = [];
          listaTmp.push(
            {
              id:faker.datatype.uuid(),
              pcurso_examen_pregunta:item.pcurso_examen_pregunta,
              pcurso_examen_respuesta:item.pcurso_examen_respuesta,
              pcurso_examen_opciones:listaTmpSub
            }
          )
          if(item.pcurso_examen_opciones.length>0){
            item.pcurso_examen_opciones.map((subItem)=>{
              listaTmpSub.push({
                id:faker.datatype.uuid(),
                pcurso_examen_opitem:subItem.pcurso_examen_opitem,
              })
            })
          }
        })
        //console.log(listaTmp)
        setExamen(listaTmp);
      }
      updateState(false)

    })
  }




  useEffect(()=>{
    getCourse();
  },[])

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h6" gutterBottom >
            Lista
        </Typography>
        <Button 
            variant="contained" 
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleModuleOpen}
        >
        Agregar
        </Button>
      </Stack>
      {examen.length > 0 ?
        <Stack spacing={0}  sx={{ mb: 4 }}>
          {examen.length > 0 &&
            examen.map((item,index) =>{
              return (
                <>
                  {index !==0 && <Divider sx={{mb:2,mt:2}} />}
                  <ListItem disablePadding key={item.id}  >
                    <IconButton>
                      <QuestionAnswerIcon />
                    </IconButton>
                    <ListItemText primary={item.pcurso_examen_pregunta} />

                    <IconButton onClick={()=>editarModulo(item)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={()=>{handleOpen(item.id)}}>
                      <AddIcon />
                    </IconButton>
                    <IconButton onClick={()=>eliminarModulo(item)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                  {item.pcurso_examen_opciones.length > 0 &&
                    <List disablePadding>
                      {item.pcurso_examen_opciones.map((itemSub,index) =>{
                        return (
                          <ListItem disablePadding sx={{ pl: 3 }}>
                            <RadioButtonUncheckedIcon sx={{mr:1,fontSize:10}}/>
                              <ListItemText secondary={itemSub.pcurso_examen_opitem} />
                              <IconButton onClick={()=>{editClass(item,itemSub)}}>
                                <EditIcon />
                              </IconButton>
                              <IconButton onClick={()=>eliminarClase(item,itemSub)}>
                                <DeleteIcon />
                              </IconButton>
                          </ListItem>
                        );
                      })}
                      <Chip 
                        sx={{ ml: 3,background:'rgba(15,245,136,.3)' }} 
                        color="success" 
                        label={
                          <div stylet={{display:'flex',justifyContent: "center",alignItems: "center"}}>
                            <DoneIcon sx={{fontSize:15,mr:1,color:'#118b09'}} />
                            <span style={{color:'#118b09'}}>{item.pcurso_examen_respuesta}</span>
                          </div>
                        }
                      />
                    </List>
                  }

                </>
              )
            })
          }
        </Stack>
      :
        <Alert severity="warning">Aun no tenemos preguntas frecuentes</Alert>
      }
      <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          sx={{mt:2}}
          onClick={registrarCurso}
      >
          Actualizar datos
      </Button>


      <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
        <Box maxWidth={700} sx={style}>
            <Card sx={{ mb: 3 }}>

            <CardContent>
                {actionModal ?
                <AgregarClase 
                    enviarExamen={()=>enviarExamen(openModuloId)} 
                    title={clase.pcurso_examen_opitem} 
                    duracion={clase.pcurso_examen_lduracion} 
                    video={clase.pcurso_examen_lvideo} 
                    action={actionModal} 
                    examenChange={examenChange} 
                    getDuration={()=>getBunySeconds(clase.pcurso_examen_lvideo)}
                />
                :
                <AgregarClase 
                    enviarExamen={()=>actualizarClase(openModuloId,clase)} 
                    title={clase.pcurso_examen_opitem} 
                    duracion={clase.pcurso_examen_lduracion} 
                    video={clase.pcurso_examen_lvideo} 
                    action={actionModal} 
                    examenChange={examenChange} 
                    getDuration={()=>getBunySeconds(clase.pcurso_examen_lvideo)}
                />
                }
            </CardContent>

            </Card>
        </Box>
      </Modal>

      <Modal
      open={openModuleModal}
      onClose={handleModuleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
        <Box maxWidth={700} sx={style}>
            <Card sx={{ mb: 3 }}>
            <CardContent>
                {actionModuleModal ?
                <AgregarModulo 
                    value={tituloModulo}
                    cambioField={fieldModulo} 
                    action={false} 
                    enviarModulo={agregarTituloModulo} 
                />
                :
                <AgregarModulo 
                    value={tituloModulo}
                    cambioField={fieldModulo} 
                    action={false} 
                    enviarModulo={()=>actualizarTituloModulo(openModuloId)} 
                    
                />
                }
            </CardContent>
            </Card>
        </Box>
      </Modal>
    </Box>
  );

}

export default CourseExamen;
