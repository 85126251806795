import { useEffect, useState,useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";

// @mui
import { 
    Grid,
    Box,
    Stack,
    Button,
    TextField
 } from '@mui/material';

 // Axios
import axios from 'axios';
import UserContext from "../../context/AuthContext";

import AddIcon from '@mui/icons-material/Add';
import ImageUpload from '../upload/image';


DiscountsDescripcion.propTypes = {
  updateState:PropTypes.func
};

function DiscountsDescripcion({updateState,...other}) {

    const { id } = useParams();
    const { jwt  } = useContext(UserContext);
    const navigate = useNavigate();
    const [imgDiscount,setImgDiscount] = useState();


    // Data universal
    const [postCurso,setPostCurso] = useState({
        fTitulo:'',
        fDescripcion:'',
        fTyc:'',
        fLink:'',
    });

    const changePostCurso = (e) =>{
        setPostCurso({
            ...postCurso,
            [e.target.name]: e.target.value
        });
    }

    // Enviar data
    const baseUrl  = 'https://api.lessin.pe/wp-json/wp/v2/descuentos';

    const registrarCurso = () =>{
        updateState(true)
        axios.put(baseUrl+'/'+id,
        {
            title:postCurso.fTitulo,
            status:"publish",
            acf:{
                pdescuentos_img:imgDiscount ? imgDiscount.image : '',
                pdescuentos_txt:postCurso.fDescripcion,
                pdescuentos_tyc:postCurso.fTyc,
                pdescuentos_link:postCurso.fLink,
            }
        },
        {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
            }
        }).then((data)=>{
            updateState(false)
            navigate('/dashboard/discounts/edit-discount/'+data.data.id);
        }).catch((error) => {
            console.log('error',error);
        });
    }

    // Get Informations
    const getCourse = () =>{
        updateState(true)
        axios.get(baseUrl+'/'+id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            }
        }).then((resp)=>{
            updateState(false)
            setPostCurso({
                fTitulo:resp.data.title.rendered,
                fDescripcion:resp.data.acf.pdescuentos_txt,
                fTyc:resp.data.acf.pdescuentos_tyc,
                fLink:resp.data.acf.pdescuentos_link,
            });

            setImgDiscount({
                image:resp.data.acf.pdescuentos_img
            })
        })
    }

    useEffect(()=>{
        getCourse();
    },[])

    return (
        <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                        <ImageUpload 
                            updateState={setImgDiscount} 
                            recomend={'Descuento: Tamaño recomendado 450x220 pixeles'} 
                            imageData={imgDiscount} 
                            height={'180px'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField 
                                    id="outlined-basic" 
                                    fullWidth 
                                    label="Titulo" 
                                    onChange={changePostCurso} 
                                    value={postCurso.fTitulo} 
                                    name="fTitulo" 
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <TextField 
                                    id="outlined-basic" 
                                    fullWidth 
                                    label="Descripcion" 
                                    onChange={changePostCurso} 
                                    value={postCurso.fDescripcion} 
                                    name="fDescripcion" 
                                    multiline
                                    rows={5}
                                />
                            </Grid>


                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <TextField 
                            id="outlined-basic" 
                            fullWidth 
                            label="Terminos y condiciones" 
                            onChange={changePostCurso} 
                            value={postCurso.fTyc} 
                            name="fTyc" 
                            multiline
                            rows={4}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <TextField 
                            id="outlined-basic" 
                            fullWidth 
                            label="Link" 
                            onChange={changePostCurso} 
                            value={postCurso.fLink} 
                            name="fLink" 
                        />
                    </Grid>

                </Grid>
            </Stack>

            <Button 
                variant="contained" 
                startIcon={<AddIcon />}
                sx={{mt:2}}
                onClick={registrarCurso}
            >
                Crear
            </Button>
        </Box>
    );

}

export default DiscountsDescripcion;
