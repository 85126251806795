import { useEffect, useState,useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";

// @mui
import { 
    Grid,
    Box,
    Stack,
    Button,
    TextField
 } from '@mui/material';

 // Axios
import axios from 'axios';
import UserContext from "../../context/AuthContext";

import AddIcon from '@mui/icons-material/Add';
import ImageUpload from '../upload/image';


BenefitEspecialista.propTypes = {
  updateState:PropTypes.func
};

function BenefitEspecialista({updateState,...other}) {

    const { id } = useParams();
    const { jwt  } = useContext(UserContext);
    const navigate = useNavigate();
    const [imgEspecialistas,setImgEspecialistas] = useState();


    // Data universal
    const [postCurso,setPostCurso] = useState({
        fEspNombre:'',
        fEspDisp:'',
    });

    const changePostCurso = (e) =>{
        setPostCurso({
            ...postCurso,
            [e.target.name]: e.target.value
        });
    }

    // Enviar data
    const baseUrl  = 'https://api.lessin.pe/wp-json/wp/v2/beneficios';

    const registrarCurso = () =>{
        updateState(true)
        axios.put(baseUrl+'/'+id,
        {
            acf:{
                pbene_esp_img:imgEspecialistas ? imgEspecialistas.image : '',
                pbene_esp_nombre:postCurso.fEspNombre,
                pbene_esp_disp:postCurso.fEspDisp,
            }
        },
        {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
            }
        }).then((data)=>{
            updateState(false)
            navigate('/dashboard/benefit/edit-benefit/'+data.data.id);
        }).catch((error) => {
            console.log('error',error);
        });
    }

    // Get Informations
    const getCourse = () =>{
        updateState(true)
        axios.get(baseUrl+'/'+id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            }
        }).then((resp)=>{
            updateState(false)
            setPostCurso({
                fEspNombre:resp.data.acf.pbene_esp_nombre,
                fEspDisp:resp.data.acf.pbene_esp_disp,
            });

            setImgEspecialistas({
                image:resp.data.acf.pbene_esp_img
            })
        })
    }

    useEffect(()=>{
        getCourse();
    },[])

    return (
        <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3}>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12} md={8}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField 
                                    id="outlined-basic" 
                                    fullWidth 
                                    label="Nombre" 
                                    onChange={changePostCurso} 
                                    value={postCurso.fEspNombre} 
                                    name="fEspNombre" 
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField 
                                    id="outlined-basic" 
                                    fullWidth 
                                    label="Descripcion" 
                                    onChange={changePostCurso} 
                                    value={postCurso.fEspDisp} 
                                    name="fEspDisp" 
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <ImageUpload 
                            updateState={setImgEspecialistas} 
                            recomend={'Especialista: Tamaño recomendado 150x150 pixeles'} 
                            imageData={imgEspecialistas} 
                            height={'180px'}
                        />
                    </Grid>


                </Grid>
            </Stack>

            <Button 
                variant="contained" 
                startIcon={<AddIcon />}
                sx={{mt:2}}
                onClick={registrarCurso}
            >
                Actualizar
            </Button>
        </Box>
    );

}

export default BenefitEspecialista;
